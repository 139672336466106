import React from 'react';
import Layout from '../../components/Layout';
import styled from 'styled-components';
import { imageWrapper } from './products.module.scss';
import ProductCard from '../../components/elements/ProductCard';
import { StaticImage } from 'gatsby-plugin-image';
import { ptyssomenes_portes } from '../../Data/pagesTexts';
import { Helmet } from 'react-helmet';

function Ptyssomenes_portes() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{'Olkis | Πτυσσόμενες πόρτες πλαστικές από PVC'}</title>
                <html lang={'el'} />
                <meta
                    name="description"
                    content={'Οι πτυσσόμενες πόρτες τύπου φυσούνα ή φυσαρμόνικα αποτελούν την ιδανική λύση για χώρους οπού δεν είναι δυνατόν να τοποθετηθούν ξύλινες πόρτες. Λόγω κατασκευής τους καταλαμβάνουν περιορισμένο χώρο και μπορούμε να τις χρησιμοποιήσουμε και για διαχωρισμό μεγάλων ενιαίων χώρων.Κατασκευάζονται από φύλλα PVC μεγάλης αντοχής. Μπορούμε να επιλέξουμε ανάμεσα από μεγάλη ποικιλία χρωμάτων ( μονόχρωμα ή απομιμήσεις ξύλου ), με τζαμάκια ή χωρίς, όπως και να κλείνουν με απλό μαγνήτη ή κανονική κλειδαριά. Οι ποιοτικές πτυσσόμενες πόρτες κατασκευάζονται ακριβώς στην διάσταση που επιθυμούμε Υπάρχει η δυνατότητα στις μεγάλες διαστάσεις ( άνω από 1,60m ) να κατασκευαστούν ως δίφυλλες.'}
                />
            </Helmet>
            <Layout>
                <MainView>
                    {/* <About backgroundColor={false} header={rollerBlindsData.headerInfo.header} text={rollerBlindsData.headerInfo.text} /> */}
                    <ProductCard header={ptyssomenes_portes.header} text={ptyssomenes_portes.text}  >
                        <StaticImage className={imageWrapper} quality={100} layout='fullWidth' src='../../images/ptyssomenesportes/ptyssomeni-porta2.jpg' alt="asdasdasdas" />
                    </ProductCard>
                </MainView>
            </Layout>
        </>
    );
}

export default Ptyssomenes_portes;

const MainView = styled.div`
min-height: 100vh;
display: flex;
flex-direction:column;
align-items: center;
background: linear-gradient(360deg, #4d4d55 0%, #f5f5f5 100%);

`;